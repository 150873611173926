import * as StorageService from 'utils/storage.service';

const host = process.env.REACT_APP_SERVER;

/*
 *  Cart Handling
 */

export function selectPrice(price, quantity) {
  const additionalUsers = quantity ? quantity : 0;
  StorageService.add('price', price);
  StorageService.add('product-quantity', additionalUsers);
}

export function selectProduct(product, quantity) {
  const additionalUsers = quantity ? quantity : 0;
  StorageService.add('product', product);
  StorageService.add('product-quantity', additionalUsers);
}

export function getPrice(price)
{
  return StorageService.get('price');
}

export function getValidPrices(interval, prices) {
  // remove prices that are inactive, not tiered,
  // and not the specified interval ('month'|'year')
  // if more than one price remains, return the first

  let price;
  if(!prices) {
    price = StorageService.get('product').prices;
  } else {
    price = prices;
  }
  const valid = price
    .filter(item => item.active)
    .filter(item => item.billing_scheme === "tiered")
    .find(item => item.recurring.interval === interval);

  return valid.length > 1 ? valid[0] : valid;
}

export function getProduct(product)
{
  return StorageService.get('product');
}

export function getProductQuantity() {
  return StorageService.get('product-quantity');
}

export function clearPrice()
{
  StorageService.remove('price');
}

export function clearProduct()
{
  StorageService.remove('product');
  StorageService.remove('product-quantity');
}

export function clearCart() {
  clearPrice();
  clearProduct();
}

/*
 *
 */
export async function findPrice(price_id) {
  const request = new Request(`${host}/api/v2/prices/${price_id}`, {
    method: 'GET',
    headers: new Headers({ "Content-Type": "application/json" }),
    credentials: 'include',
  });

  const response = await fetch(request);

  return new Promise((resolve, reject) => {
    return (response) 
      ? resolve() 
      : reject();
  });
}

export async function findProduct(product_id) {
  const request = new Request(`${host}/api/v2/products/${product_id}`, {
    method: 'GET',
    headers: new Headers({ "Content-Type": "application/json" }),
    credentials: 'include',
  });

  return fetch(request)
    .then(response => response.json());
}

/*
 *  Payment Handling
 */
export async function createPaymentIntent(price_id) 
{
  const request = new Request(`${host}/api/v2/payment-intents?price_id=${price_id}`, {
    method: 'POST',
    headers: new Headers({ "Content-Type": "application/json" }),
    credentials: 'include',
  });

  return fetch(request).then(response => response.json())
}


export async function calculate ({ price_id, quantity = 1 })
{
  const body = JSON.stringify({
    price_id: price_id,
    quantity: quantity
  });

  const request = new Request(`${host}/api/v2/calculate`, {
    method: 'POST',
    headers: new Headers({ "Content-Type": "application/json" }),
    credentials: 'include',
    body: body
  });

  return fetch(request).then(response => response.json())  
}


export async function checkout(params) 
{
  // const body = new FormData();
  // body.append('price_id', params.price_id);
  // body.append('payment_method', params.payment_method);

  const body = JSON.stringify({
    price_id: params.price_id,
    payment_method: params.payment_method,
    quantity: params.quantity || 1 //how many TOTAL seats (also included seats)
  });

  const request = new Request(`${host}/api/v2/checkout`, {
    method: 'POST',
    headers: new Headers({ "Content-Type": "application/json" }),
    credentials: 'include',
    body: body
  });

  return fetch(request).then(response => response.json())
}

export function getURLParams() {
  //for getting plan params from URL
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  return params;
}
